body {
  margin: 0;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button {
  z-index: 257;
  position: relative; background-image: linear-gradient(#9452b3, #9452c3); background-color: #9452b3; border-radius: 50%; width: 89px; height: 89px; box-shadow: rgb(0, 0, 0) 0.5px 0.5px 5px;
}
